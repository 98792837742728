import { useEffect } from 'react';

import { useRegistrationProps } from '@/components/Modals/ModalCheckout/hooks/types.ts';
import { RegisterNotificationResponse } from '@/components/Modals/ModalCheckout/types.ts';
import { USER_SIGNED_UP_EVENT } from '@/const/dataLayerEvents.ts';
import { useFingerprint } from '@/hooks/useFingerprint.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import { userApi } from '@/services/userServices';
import { selectUser, setAuth } from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';
import { facebookEventMapping, trackFbEvent } from '@/utils/facebookUtils';

export const useRegistration = ({
  registerConnection,
  shouldSaveCard,
  saveCardDetails,
}: useRegistrationProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const { postSetFingerprint } = useFingerprint();

  useEffect(() => {
    if (registerConnection) {
      registerConnection.on(
        'RegistrationNotification',
        (res: RegisterNotificationResponse) => {
          dispatch(setAuth(res));
          postSetFingerprint();
          dispatch(userApi.util.invalidateTags(['User']));

          trackFbEvent(facebookEventMapping.user_signed_up);

          pushToDataLayer(USER_SIGNED_UP_EVENT, user, {
            auth_method: 'email',
            source: 'checkout_page',
          });

          if (shouldSaveCard && saveCardDetails) {
            saveCardDetails(res.userId);
          }
        },
      );
    }

    return () => registerConnection?.off('RegistrationNotification');
  }, [registerConnection, shouldSaveCard]);
};
