import { RouteObject } from 'react-router-dom';

import { Layout } from '@/layout';

import { Routes } from '../types/enum.ts';
import { CommonRoutes } from './CommonRoutes.tsx';
import {
  BuyTokensPage, // GalleryPage,
  // GenerateImagePage,
  SettingsPage,
} from './RouteImports.tsx';

export const ProtectedRoutes: RouteObject = {
  path: '/',
  element: <Layout />,
  children: [
    ...CommonRoutes,
    // { path: Routes.GALLERY_PAGE, element: <GalleryPage /> },
    // { path: Routes.GENERATE_IMAGE_PAGE, element: <GenerateImagePage /> },
    { path: Routes.SETTINGS_PAGE, element: <SettingsPage /> },
    { path: Routes.BUY_TOKENS, element: <BuyTokensPage /> },
    {
      path: Routes.HIDDEN_TOKENS_PAGE,
      element: <BuyTokensPage hidden />,
    },
  ],
};
