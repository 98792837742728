import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import classNames from 'classnames';
import { MouseEventHandler, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Button } from '@/components/Button';
import { LogoSvitlo } from '@/components/Mirror/Logo';
import { ModalAuthContainer } from '@/components/Modals/ModalAuthContainer';
import {
  ANONYMOUS_USER_ID_KEY,
  APP_NAME,
  CONFIRMED_TERMS_KEY,
  IS_MIRROR_SVITLO_APP,
  MAX_DISCOUNT_PERCENT,
} from '@/const';
import { SIGNUP_BUTTON_CLICKED_EVENT } from '@/const/dataLayerEvents.ts';
import { FingerprintData } from '@/hooks/useFingerprint.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import { Tokens } from '@/layout/Header/Tokens';
import { SidebarSvitlo } from '@/layout/Sidebar/SidebarSvitlo';
import { useGoogleLoginMutation } from '@/services/authServices';
import {
  selectIsAuth,
  selectIsSidebarOpened,
  selectUser,
  setAuth,
  setIsSidebarOpened,
} from '@/store/public';
import { AppName } from '@/types';
import { MediaQueries, Routes } from '@/types/enum.ts';
import { getFingerprintHash } from '@/utils/getFingerprintHash.ts';
import { pushToDataLayer } from '@/utils/gtm.ts';
import { ls } from '@/utils/localStorage.ts';

import { Profile } from './Profile';

import styles from './styles.module.scss';

import CrownIcon from '@/assets/icons/crown.svg?react';
import MenuIcon from '@/assets/icons/header/menu.svg?react';
import HeartLogoIcon from '@/assets/icons/heart-logo.svg?react';
import LogoMirrorIcon from '@/assets/icons/logo-mirror.svg?react';
import LogoIcon from '@/assets/icons/logo.svg?react';

const LOGO: Record<AppName, JSX.Element> = {
  main: (
    <>
      <HeartLogoIcon width={26} height={26} />
      <LogoIcon className={styles.logo} />
    </>
  ),
  mainMirror: (
    <>
      <HeartLogoIcon width={26} height={26} />
      <LogoMirrorIcon className={styles.logo} />
    </>
  ),
  svitlo: <LogoSvitlo />,
};

export const Header = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(MediaQueries.TABLET);

  const isSidebarOpened = useAppSelector(selectIsSidebarOpened);
  const isAuth = useAppSelector(selectIsAuth);
  const user = useAppSelector(selectUser);

  // const [modal18PlusVariant, setModal18PlusVariant] =
  //   useState<Modal18PlusVariant>('');
  const [authModalVariant, setAuthModalVariant] = useState<
    'register' | 'login' | ''
  >('');

  const [googleLogin] = useGoogleLoginMutation();

  useGoogleOneTapLogin({
    onSuccess: async (credentialResponse) => {
      const fingerprintData = (await getFingerprint(true)) as FingerprintData;
      const payload = {
        googleAccessToken: credentialResponse?.credential || '',
        fingerprint: getFingerprintHash(fingerprintData),
        terms: ls.get(CONFIRMED_TERMS_KEY, ''),
        userId: ls.get(ANONYMOUS_USER_ID_KEY, ''),
      };

      const { data } = await googleLogin(payload);
      if (data) {
        dispatch(setAuth({ ...data, isLoginOrRegister: true }));
        window.location.reload();
      }
    },
    disabled: Boolean(!user || user.email),
    use_fedcm_for_prompt: true,
    onError: () => {
      console.log('Login Failed');
    },
  });

  // useEffect(() => {
  //   if (IS_MIRROR_SVITLO_APP) {
  //     return;
  //   }
  //
  //   const shouldShowConfirmationModal =
  //     ((user?.nEnabled && !user?.isNConfirmed) ||
  //       (user && !user?.isSConfirmed)) &&
  //     !location.pathname.startsWith(Routes.TERMS_OF_SERVICE_PAGE) &&
  //     !location.pathname.startsWith(Routes.COOKIES_POLICY_PAGE);
  //
  //   if (shouldShowConfirmationModal) {
  //     const isNSFW = user?.nEnabled;
  //     const modalVariant = isNSFW ? 'nsfw' : 'sfw';
  //     setModal18PlusVariant(modalVariant);
  //   } else {
  //     setModal18PlusVariant('');
  //   }
  // }, [user, location]);

  const handleRegisterClick: MouseEventHandler<HTMLButtonElement> = () => {
    pushToDataLayer(SIGNUP_BUTTON_CLICKED_EVENT, user);
    setAuthModalVariant('register');
    dispatch(setIsSidebarOpened(false));
  };

  // const closeModal18Plus = () => {
  //   setModal18PlusVariant('');
  // };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.nav}>
            <button
              id="sidebar_button"
              aria-label="Menu"
              className={classNames(
                styles.menu,
                isSidebarOpened && styles.opened,
              )}
              onClick={() => dispatch(setIsSidebarOpened(!isSidebarOpened))}
            >
              <MenuIcon />
            </button>
            <NavLink
              to={Routes.MAIN_PAGE}
              onClick={() => dispatch(setIsSidebarOpened(false))}
              className={styles.logoLink}
            >
              {LOGO[APP_NAME]}
            </NavLink>

            {!isMobile && IS_MIRROR_SVITLO_APP && <SidebarSvitlo />}
          </div>

          {user ? (
            <div className={styles.flexContainer}>
              {user?.activeSubscription && isAuth && <Tokens />}

              {!user?.activeSubscription && !IS_MIRROR_SVITLO_APP && (
                <Button
                  link={Routes.PRICING_PAGE}
                  id="subscr_btn"
                  state={{ source: 'header' }}
                  onClick={() => dispatch(setIsSidebarOpened(false))}
                  className={classNames(
                    styles.subscriptionLink,
                    isAuth && styles.loggedIn,
                  )}
                >
                  <CrownIcon />
                  <span>
                    Subscribe{' '}
                    <span className={styles.accent}>
                      {MAX_DISCOUNT_PERCENT}% OFF
                    </span>
                  </span>
                </Button>
              )}

              {isAuth ? (
                <Profile />
              ) : (
                <>
                  <Button
                    id="reg_btn"
                    className={styles.register}
                    onClick={handleRegisterClick}
                  >
                    {IS_MIRROR_SVITLO_APP ? 'Sign up' : 'Register'}
                  </Button>
                  <Button
                    id="log_btn"
                    variant="outline"
                    className={styles.login}
                    onClick={() => {
                      setAuthModalVariant('login');
                      dispatch(setIsSidebarOpened(false));
                    }}
                  >
                    Login
                  </Button>
                </>
              )}
            </div>
          ) : (
            <Skeleton
              variant="rectangular"
              className={styles.actionsSkeleton}
            />
          )}
        </div>
      </header>

      {/*{!!modal18PlusVariant && (*/}
      {/*  <Modal18Plus*/}
      {/*    open*/}
      {/*    variant={modal18PlusVariant}*/}
      {/*    onClose={closeModal18Plus}*/}
      {/*    onAction={closeModal18Plus}*/}
      {/*  />*/}
      {/*)}*/}

      {!!authModalVariant && (
        <ModalAuthContainer
          defaultVariant={authModalVariant}
          onClose={() => setAuthModalVariant('')}
        />
      )}
    </>
  );
};
