import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import { MouseEventHandler, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { RouteGuard } from '@/components/RouteGuard';
import { IS_MIRROR_SVITLO_APP } from '@/const';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import { SIDEBAR_LINKS } from '@/layout/Sidebar/constants.ts';
import {
  selectIsAnimationDisabled,
  selectIsSidebarOpened,
  setIsAnimationDisabled,
  setIsSidebarOpened,
} from '@/store/public';
import { MediaQueries, Routes } from '@/types/enum.ts';

import { useScroll } from '../../hooks/useScroll.tsx';

import styles from './styles.module.scss';

import ContactUsIcon from '@/assets/icons/sidebar/contact-us.svg?react';

export const Sidebar = () => {
  const dispatch = useAppDispatch();
  const isTableView = useMediaQuery(MediaQueries.TABLET);

  const isSidebarOpened = useAppSelector(selectIsSidebarOpened);
  const isAnimationDisabled = useAppSelector(selectIsAnimationDisabled);

  const isScrollEnabled = !isTableView || (isTableView && !isSidebarOpened);
  useScroll(isScrollEnabled);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setIsAnimationDisabled(false));
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  const closeSidebar = () => {
    dispatch(setIsSidebarOpened(false));
  };

  const handleBackdropClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (isSidebarOpened && (isTableView || e.target === e.currentTarget)) {
      closeSidebar();
    }
  };

  const handleMouseEnter: MouseEventHandler<HTMLDivElement> = () => {
    if (isTableView) return;
    dispatch(setIsSidebarOpened(true));
  };

  const handleMouseLeave: MouseEventHandler<HTMLDivElement> = () => {
    if (isTableView) return;
    closeSidebar();
  };

  return (
    <div
      onClick={handleBackdropClick}
      className={classNames(
        styles.sidebar,
        !isSidebarOpened && styles.collapsed,
        isSidebarOpened && isTableView && styles.blurred,
      )}
    >
      <div
        id="sidebar_hover_btn"
        className={classNames(
          styles.sidebarContent,
          !isSidebarOpened && styles.collapsed,
          isAnimationDisabled && styles.disabledAnimation,
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ul className={styles.menuList}>
          {SIDEBAR_LINKS().map(({ Icon, ...link }) => {
            if (link.disabled) return null;

            return (
              <li key={link.route}>
                <RouteGuard
                  id={link.id}
                  variant="navlink"
                  route={link.route}
                  className={classNames(
                    styles.route,
                    link.className,
                    !isSidebarOpened && styles.collapsed,
                  )}
                  activeClassName={styles.active}
                  skipAuthCheck={!link.guard}
                  needSubscription={link.needSubscription}
                  state={link.state}
                >
                  <>
                    {!IS_MIRROR_SVITLO_APP && (
                      <span className={styles.iconWrapper}>
                        <Icon />
                      </span>
                    )}
                    <span className={styles.label}>{link.label}</span>
                  </>
                </RouteGuard>
              </li>
            );
          })}
        </ul>

        <ul className={classNames(styles.menuList, styles.bottom)}>
          {/*<li className={styles.route}>*/}
          {/*  <div className={styles.content}>*/}
          {/*    <DiscordLogo size={16} />*/}
          {/*    <span>Discord</span>*/}
          {/*  </div>*/}
          {/*</li>*/}
          <li>
            <NavLink
              to={Routes.CONTACT_US_PAGE}
              id="contact_btn"
              className={({ isActive }) =>
                classNames(
                  styles.route,
                  isActive && styles.active,
                  !isSidebarOpened && styles.collapsed,
                )
              }
            >
              <span className={styles.iconWrapper}>
                <ContactUsIcon />
              </span>
              <span className={styles.label}>Contact Us</span>
            </NavLink>
          </li>
          {/*<li>*/}
          {/*  <NavLink*/}
          {/*    to={Routes.AFFILIATE_PAGE}*/}
          {/*    className={({ isActive }) =>*/}
          {/*      isActive*/}
          {/*        ? classNames(styles.active, styles.route)*/}
          {/*        : styles.route*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <div className={styles.content}>*/}
          {/*      <Trophy size={16} />*/}
          {/*      <span>Affiliate Program</span>*/}
          {/*    </div>*/}
          {/*  </NavLink>*/}
          {/*</li>*/}

          <li
            className={classNames(
              styles.terms,
              !isSidebarOpened && styles.collapsed,
            )}
          >
            <Link
              to={Routes.TERMS_OF_SERVICE_PAGE}
              id="service_link"
              className={styles.temrsText}
            >
              Terms of Service
            </Link>
            <Link
              to={Routes.PRIVACY_POLICY_PAGE}
              id="policy_link"
              className={styles.termsText}
            >
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
