import { HubConnection } from '@microsoft/signalr';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';

import { Dialog } from '@/pages/chat/DialogList/types.ts';
import {
  MessageType,
  ModelStatusResponse,
} from '@/pages/chat/Messenger/Chat/types.ts';
import { RootState } from '@/store';
import { Routes } from '@/types/enum.ts';
import { getIsNSFWContent } from '@/utils/getIsNSFWUser.ts';
import { getModelType } from '@/utils/getModelType.ts';

export type ChatState = {
  hubConnection: HubConnection | null;
  isLoadingMessages: boolean;
  dialogs: Dialog[];
  isLoadingDialogs: boolean;
  chatId: string;
  modalClearChatId: string;
  modalDeleteChatId: string;
  isModalInsufficientTokensOpened: boolean;
  threadMessages: MessageType[];
};

const initialState: ChatState = {
  hubConnection: null,
  isLoadingMessages: true,
  dialogs: [],
  isLoadingDialogs: true,
  chatId: '',
  modalClearChatId: '',
  modalDeleteChatId: '',
  isModalInsufficientTokensOpened: false,
  threadMessages: [],
};

const index = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setHubConnection(state, action: PayloadAction<HubConnection | null>) {
      state.hubConnection = action.payload;
    },

    setMessages(state, action: PayloadAction<MessageType[]>) {
      state.dialogs = state.dialogs.map((dialog) => {
        if (dialog?.chat.id === state.chatId) {
          return {
            ...dialog,
            messages: action.payload.filter(
              (message) =>
                message.message || message.videoUrl || message.imageUrl,
            ),
          };
        }
        return dialog;
      });
    },

    updateMessages(
      state,
      { payload: { newMessage } }: PayloadAction<{ newMessage: MessageType }>,
    ) {
      if (!newMessage.message && !newMessage.videoUrl && !newMessage.imageUrl) {
        return;
      }

      let updatedDialogs: Dialog[] = [];
      let isNewMessage = false;

      for (let i = 0; i < state.dialogs.length; i++) {
        const dialog = state.dialogs[i];

        if (dialog.chat.id === newMessage.chatEntityId) {
          const latestMessageIndex = dialog.lastMessages.findIndex(
            (message) => message.id === newMessage.id,
          );
          const messageIndex = dialog.messages?.findIndex(
            (message) => message.id === newMessage.id,
          );

          const updatedLastMessages = [...dialog.lastMessages];
          const updatedMessages = [...(dialog.messages || [])];
          if (latestMessageIndex !== -1) {
            updatedLastMessages[latestMessageIndex] = newMessage;
            updatedMessages[messageIndex || 0] = newMessage;
          } else {
            updatedLastMessages.push(newMessage);
            updatedMessages.push(newMessage);
            isNewMessage = true;
          }

          const updatedDialog = {
            ...dialog,
            lastMessages: updatedLastMessages,
            messages: updatedMessages.filter(
              (message) =>
                message.message || message.videoUrl || message.imageUrl,
            ),
          };

          if (isNewMessage) {
            updatedDialogs = [
              updatedDialog,
              ...state.dialogs.slice(0, i),
              ...state.dialogs.slice(i + 1),
            ];
          } else {
            updatedDialogs = [
              ...state.dialogs.slice(0, i),
              updatedDialog,
              ...state.dialogs.slice(i + 1),
            ];
          }
          break;
        }
      }

      if (updatedDialogs.length === 0) {
        updatedDialogs = state.dialogs;
      }

      state.dialogs = updatedDialogs;
    },

    updateDialogImages(
      state,
      { payload }: PayloadAction<{ images: string[]; imagesLength: number }>,
    ) {
      const newBodyImages =
        payload.imagesLength === 2
          ? [payload.images[1], payload.images[0]]
          : payload.images;

      state.dialogs = state.dialogs.map((dialog) => {
        if (dialog?.chat.id === state.chatId) {
          return {
            ...dialog,
            chat: {
              ...dialog.chat,
              personModelEntity: {
                ...dialog.chat.personModelEntity,
                bodyImages: newBodyImages,
              },
            },
          };
        }
        return dialog;
      });
    },

    setIsLoadingMessages(state, action: PayloadAction<boolean>) {
      state.isLoadingMessages = action.payload;
    },

    setDialogs(state, action: PayloadAction<Dialog[]>) {
      if (action.payload?.length && state.dialogs?.length) return;
      state.dialogs = action.payload;
    },

    setIsLoadingDialogs(state, action: PayloadAction<boolean>) {
      state.isLoadingDialogs = action.payload;
    },

    chatCreated(state, action: PayloadAction<Dialog>) {
      const isChatExist = state.dialogs.some(
        (dialog) => dialog?.chat.id === action.payload.chat.id,
      );
      if (isChatExist) return;
      state.dialogs = [action.payload, ...state.dialogs];
    },

    chatDeleted(
      state,
      {
        payload: { id, navigate },
      }: PayloadAction<{ id: string; navigate: NavigateFunction }>,
    ) {
      state.dialogs = state.dialogs.filter((dialog) => dialog.chat.id !== id);
      if (state.chatId === id) {
        navigate(Routes.CHAT_PAGE);
      }
    },

    chatCleared(state, { payload: chatId }: PayloadAction<string>) {
      state.dialogs = state.dialogs.map((dialog) =>
        dialog?.chat.id === chatId
          ? {
              ...dialog,
              messages: [],
              lastMessages: [],
            }
          : dialog,
      );
    },

    setChatId(state, action: PayloadAction<string>) {
      state.chatId = action.payload;
    },

    setModelChatStatus(state, action: PayloadAction<ModelStatusResponse>) {
      state.dialogs = state.dialogs.map((dialog) =>
        dialog?.chat.personModelEntityId === action.payload.personModelId
          ? {
              ...dialog,
              chat: {
                ...dialog.chat,
                status: action.payload.status,
              },
            }
          : dialog,
      );
    },

    setModalClearChatId: (state, action: PayloadAction<string>) => {
      state.modalClearChatId = action.payload;
    },

    setModalDeleteChatId: (state, action: PayloadAction<string>) => {
      state.modalDeleteChatId = action.payload;
    },

    setIsModalInsufficientTokensOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isModalInsufficientTokensOpened = action.payload;
    },

    setDialogInput(state, action: PayloadAction<string>) {
      state.dialogs = state.dialogs.map((dialog) => {
        if (dialog?.chat.id === state.chatId) {
          return {
            ...dialog,
            chat: {
              ...dialog.chat,
              input: action.payload,
            },
          };
        }
        return dialog;
      });
    },

    setThreatMessages: (state, action: PayloadAction<MessageType[]>) => {
      state.threadMessages = action.payload;
    },

    updateChatIdByModelId: (state, action: PayloadAction<string>) => {
      state.chatId =
        state.dialogs.find(
          (dialog) => dialog?.chat.personModelEntityId === action.payload,
        )?.chat.id || '';
    },

    setMessageReaction(
      state,
      action: PayloadAction<{ messageId: string; isLike: boolean }>,
    ) {
      state.dialogs = state.dialogs.map((dialog) => {
        if (dialog?.chat.id === state.chatId) {
          return {
            ...dialog,
            messages: dialog.messages?.map((message) => {
              if (message.id === action.payload.messageId) {
                return {
                  ...message,
                  isLiked: action.payload.isLike,
                  isDisliked: !action.payload.isLike,
                };
              }
              return message;
            }),
          };
        }
        return dialog;
      });
    },
  },
});

export const selectHubConnection = (state: RootState) =>
  state.chat.hubConnection;
export const selectIsLoadingMessages = (state: RootState) =>
  state.chat.isLoadingMessages;
export const selectDialogs = (state: RootState) => state.chat.dialogs;
export const selectCurrentDialog = createSelector(
  (state: RootState) => state.chat.dialogs,
  (state: RootState) => state.chat.chatId,
  (dialogs, chatId) => dialogs.find((dialog) => dialog?.chat.id === chatId),
);
export const selectCurrentDialogModelId = createSelector(
  selectCurrentDialog,
  (dialog) => dialog?.chat.personModelEntityId || '',
);
export const selectCurrentDialogModelName = createSelector(
  selectCurrentDialog,
  (dialog) => {
    const firstName = dialog?.chat.personModelEntity.firstName || '';
    const lastName = dialog?.chat.personModelEntity.lastName || '';
    return `${firstName} ${lastName}`;
  },
);
export const selectCurrentDialogModelType = createSelector(
  selectCurrentDialog,
  (dialog) => getModelType(dialog?.chat.personModelEntity.isPublicModel),
);
export const selectPrePaywallImage = createSelector(
  (state: RootState) => state.chat.dialogs,
  (state: RootState) => state.chat.chatId,
  (state: RootState) => state.public.user,
  (dialogs, chatId, user) => {
    const currentDialog = dialogs.find((dialog) => dialog?.chat.id === chatId);
    const secondModelImage =
      currentDialog?.chat?.personModelEntity?.bodyImages?.[0] || '';
    const isNSFWContent = getIsNSFWContent(user);

    return isNSFWContent
      ? currentDialog?.chat?.personModelEntity?.paywallImage || secondModelImage
      : secondModelImage;
  },
);

export const selectIsLoadingDialogs = (state: RootState) =>
  state.chat.isLoadingDialogs;
export const selectChatId = (state: RootState) => state.chat.chatId;
export const selectModalClearChatId = (state: RootState) =>
  state.chat.modalClearChatId;
export const selectModalDeleteChatId = (state: RootState) =>
  state.chat.modalDeleteChatId;
export const selectIsModalInsufficientTokensOpened = (state: RootState) =>
  state.chat.isModalInsufficientTokensOpened;
export const selectCurrentChatStatus = (state: RootState) =>
  state.chat.dialogs.find((dialog) => dialog?.chat.id === state.chat.chatId)
    ?.chat.status || 'Offline';
export const selectCurrentDialogInput = (state: RootState) =>
  state.chat.dialogs.find((dialog) => dialog?.chat.id === state.chat.chatId)
    ?.chat?.input || '';
export const selectThreatMessages = (state: RootState) =>
  state.chat.threadMessages;

export const {
  setHubConnection,
  setMessages,
  updateMessages,
  setDialogs,
  setIsLoadingDialogs,
  chatCreated,
  chatDeleted,
  chatCleared,
  setModalClearChatId,
  setModalDeleteChatId,
  setIsLoadingMessages,
  setChatId,
  setModelChatStatus,
  setIsModalInsufficientTokensOpened,
  updateDialogImages,
  setDialogInput,
  setThreatMessages,
  updateChatIdByModelId,
  setMessageReaction,
} = index.actions;
export default index.reducer;
