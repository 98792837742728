import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Loader } from '@/components/Loader';
import { LogoSvitlo } from '@/components/Mirror/Logo';
import { Modal } from '@/components/Modals/Modal';
import { YunoPaymentErrors } from '@/components/Modals/ModalCheckout/YunoPayment/YunoPaymentForm/types.ts';
import { YUNO_ERRORS } from '@/components/Modals/ModalCheckout/YunoPayment/constants.ts';
import { PaymentCardSelection } from '@/components/Modals/ModalCheckout/components/CardSelection';
import { PaymentFailed } from '@/components/Modals/ModalCheckout/components/Failed';
import PaymentOrderSummary from '@/components/Modals/ModalCheckout/components/OrderSummary';
import { PaymentSuccess } from '@/components/Modals/ModalCheckout/components/Success';
import {
  getIsTokensProduct,
  getProductDetails,
  normalizeSubscription,
} from '@/components/Modals/ModalCheckout/helpers.ts';
import { useRegistration } from '@/components/Modals/ModalCheckout/hooks/useRegistration.tsx';
import {
  CurrentPaymentState,
  ModalCheckoutProps,
  PaymentNotificationResponse,
  ProductType,
} from '@/components/Modals/ModalCheckout/types.ts';
import { APP_NAME } from '@/const';
import {
  CHECKOUT_PAGE_OPENED_EVENT,
  PAYMENT_FAILED_EVENT,
  PURCHASE_EVENT,
} from '@/const/dataLayerEvents.ts';
import { useContentType } from '@/hooks/useContentType.tsx';
import { useHubConnection } from '@/hooks/useHubConnection.tsx';
import { usePaymentIntentYuno } from '@/hooks/usePaymentIntentYuno.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import { getProductPeriod } from '@/pages/pricing/PricingBlock/helpers.tsx';
import {
  paymentsApi,
  useGetUserCardInfoYunoQuery,
} from '@/services/paymentsServices';
import { userApi } from '@/services/userServices';
import {
  selectIsAuth,
  selectUserCards,
  setTokens,
  updateUserSubscription,
} from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';
import { navigateAfterPurchase } from '@/utils/navigateAfterPurchase.ts';

import { PaymentForm } from './YunoPaymentForm';

import styles from './styles.module.scss';

import ArrowLeftIcon from '@/assets/icons/arrow-left.svg?react';
import LogoMirrorIcon from '@/assets/icons/logo-mirror.svg?react';
import LogoIcon from '@/assets/icons/logo.svg?react';
import { facebookEventMapping, trackFbEvent } from '@/utils/facebookUtils';

const LOGO = {
  main: <LogoIcon />,
  mainMirror: <LogoMirrorIcon />,
  svitlo: <LogoSvitlo />,
};

export const YunoPayment = ({
  onClose,
  productTitle,
  selectedProduct,
  handlePaymentSuccess,
  paywallType,
  additionalDiscountPercent = 0,
  disableAppearanceAnimation,
}: ModalCheckoutProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');
  const userIdFromRedirect = searchParams.get('userId');
  const paymentSystem = searchParams.get('paymentSystem');
  const isTokensProduct = getIsTokensProduct(selectedProduct);
  const { initialTotalPrice, paywall_type, product_id, eventParams } =
    getProductDetails(selectedProduct, additionalDiscountPercent, paywallType);

  const { user, isNSFWContent } = useContentType();
  const isAuth = useAppSelector(selectIsAuth);
  const userCardInfo = useAppSelector(selectUserCards);

  const [currentStep, setCurrentStep] =
    useState<CurrentPaymentState>('loading');
  const [isFromRedirect] = useState(!!orderId);
  const [errorCode, setErrorCode] = useState('');
  const [totalPrice, setTotalPrice] = useState(initialTotalPrice);
  const [freeTokensAmount, setFreeTokensAmount] = useState(0);
  const [resultProductInfo, setResultProductInfo] =
    useState<ProductType | null>(null);
  const isTestGroup = user?.featureGroup === 'experiment_example_test_group';

  const { connection, initializeConnection, closeConnection } =
    useHubConnection({
      url: 'paymentNotificationHub',
    });
  const {
    connection: registerConnection,
    initializeConnection: initializeRegister,
    closeConnection: closeRegister,
  } = useHubConnection({
    url: 'registrationNotificationHub',
  });

  const {
    initializePayment,
    resetPaymentData,
    handleSubmitPayment,
    handleEmailChange,
    userEmail,
    emailError,
  } = usePaymentIntentYuno({
    initializeConnection,
    initializeRegister,
    isPurchaseTokens: isTokensProduct,
    productId: selectedProduct?.id,
    currentStep,
    setCurrentStep,
    additionalDiscountPercent,
    setErrorCode,
  });

  useGetUserCardInfoYunoQuery(null, {
    skip: !isAuth,
  });

  useRegistration({ registerConnection });

  useEffect(() => {
    trackFbEvent(facebookEventMapping.checkout_page_opened);

    pushToDataLayer(CHECKOUT_PAGE_OPENED_EVENT, user, {
      ...eventParams,
      paywall_type,
      product_id,
      price_usd: `${totalPrice}`,
      is_pre_paywall_shown: (!isTestGroup).toString(),
    });

    if (isFromRedirect) {
      const queryParams = userIdFromRedirect
        ? `?userId=${userIdFromRedirect}&orderId=${orderId}&paymentSystem=${paymentSystem}`
        : `?orderId=${orderId}&paymentSystem=${paymentSystem}`;
      setCurrentStep('paymentProcessing');
      initializeConnection(queryParams);
      initializeRegister(queryParams);
      setSearchParams({}, { replace: true });
    } else {
      userCardInfo?.length
        ? setCurrentStep('cardSelection')
        : initializePayment();
    }

    return closeRegister;
  }, []);

  useEffect(() => {
    if (!connection) return;

    connection.on('PaymentNotification', (res: PaymentNotificationResponse) => {
      const isSubscription = res.product?.isSubscriptionProduct;
      const priceUSD = res.amountPaid / 100;
      res.amountPaid && setTotalPrice(priceUSD);
      const analyticPaywallType = isSubscription
        ? 'default_subscription'
        : 'default_tokens';
      const analyticProductId = isSubscription
        ? `subscription_${getProductPeriod(res.product?.billingPeriod?.unit, res.product?.billingPeriod?.value)}_${res.product?.productPrice}`
        : `tokens_${res.product?.tokenAmount}_${res.product?.productPrice}`;

      if (res.success) {
        setResultProductInfo({
          ...res.product,
          amountPaid: res.amountPaid,
        });

        trackFbEvent(facebookEventMapping.purchase);

        pushToDataLayer(PURCHASE_EVENT, user, {
          ...eventParams,
          paywall_type: analyticPaywallType,
          product_id: analyticProductId,
          price_usd: `${priceUSD}`,
          random_number: Date.now().toString(),
          is_pre_paywall_shown: (!isTestGroup).toString(),
        });

        const normalizedSubscription = normalizeSubscription(res.subscription);
        if (normalizedSubscription) {
          dispatch(updateUserSubscription(normalizedSubscription));
        }
        dispatch(setTokens(res.remainingBalance));
        dispatch(paymentsApi.util.invalidateTags(['UserCardInfo']));
        dispatch(paymentsApi.util.invalidateTags(['ActiveSubscription']));
        dispatch(paymentsApi.util.invalidateTags(['Products']));
        dispatch(paymentsApi.util.invalidateTags(['UpsellProducts']));
        dispatch(userApi.util.invalidateTags(['User']));
        setCurrentStep('success');
      } else {
        resetPaymentData();

        pushToDataLayer(PAYMENT_FAILED_EVENT, user, {
          ...eventParams,
          paywall_type: analyticPaywallType,
          product_id: analyticProductId,
          price_usd: `${priceUSD}`,
          is_pre_paywall_shown: (!isTestGroup).toString(),
        });

        setErrorCode(res.errorCode || '');
        setCurrentStep('fail');
      }
    });

    connection.on('RedirectNotification', (res: { redirectUrl: string }) => {
      if (res?.redirectUrl) window.location.href = res.redirectUrl;
    });

    connection.on('FailedPaymentNotification', (res: { bonus: number }) => {
      setFreeTokensAmount(res?.bonus || 20);
    });

    return closeConnection;
  }, [connection]);

  const handleTryAgain = () => {
    resetPaymentData();
    setFreeTokensAmount(0);
    if (userCardInfo?.length) {
      setCurrentStep('cardSelection');
    } else {
      setCurrentStep('loading');
      initializePayment();
    }
  };

  const handleClose = () => {
    if (currentStep === 'paymentProcessing' || freeTokensAmount) return;
    if (currentStep === 'fail') {
      handleTryAgain();
      return;
    }
    if (currentStep === 'success') {
      handlePaymentSuccess?.();
      if (paywallType === 'default_tokens') {
        navigateAfterPurchase(navigate);
      }
    }
    resetPaymentData();
    setFreeTokensAmount(0);
    onClose(!resultProductInfo);
  };

  const handleBackClick = () => {
    setCurrentStep('cardSelection');
    resetPaymentData();
  };

  const showLoader = currentStep === 'paymentProcessing';
  const showOrderSummary = currentStep === 'cardSelection' && !isFromRedirect;
  const showBackButton =
    !!userCardInfo?.length &&
    ['resignForm', 'paymentForm'].includes(currentStep);
  const shouldSetWidth = ['loading', 'paymentForm', 'resignForm'].includes(
    currentStep,
  );
  const showPaymentForm = [
    'loading',
    'paymentForm',
    'paymentProcessing',
  ].includes(currentStep);

  const renderOrderSummary = (className?: string) =>
    selectedProduct && (
      <PaymentOrderSummary
        productTitle={productTitle}
        totalPrice={totalPrice}
        selectedProduct={selectedProduct}
        additionalDiscountPercent={additionalDiscountPercent}
        className={className}
      />
    );

  return (
    <Modal
      onClose={handleClose}
      disableBackdropClick
      maxWidth={shouldSetWidth && isNSFWContent ? 900 : 600}
      containerClassName={styles.modalContainer}
      noPadding={!!freeTokensAmount}
      disableAppearanceAnimation={disableAppearanceAnimation}
      closeButtonClassName={styles.closeModalButton}
      closeButtonStyle={
        currentStep === 'paymentProcessing' || freeTokensAmount
          ? 'hidden'
          : 'absolute'
      }
    >
      {showBackButton && (
        <button onClick={handleBackClick} className={styles.backButton}>
          <ArrowLeftIcon />
          Back
        </button>
      )}

      {!freeTokensAmount && currentStep !== 'fail' && (
        <div id="pay_frame" className={styles.logoWrapper}>
          {LOGO[APP_NAME]}
        </div>
      )}

      {showLoader && <Loader className={styles.loader} />}

      {showPaymentForm && (
        <PaymentForm
          OrderSummary={renderOrderSummary(styles.orderSummary)}
          handleSubmitPayment={handleSubmitPayment}
          userEmail={userEmail}
          handleEmailChange={handleEmailChange}
          emailError={emailError}
          showLoader={currentStep === 'loading'}
          isHidden={currentStep === 'paymentProcessing'}
        />
      )}

      {currentStep === 'cardSelection' && (
        <PaymentCardSelection
          initializePayment={initializePayment}
          setCurrentStep={setCurrentStep}
          isYunoPayment
        />
      )}

      {currentStep === 'success' && (
        <PaymentSuccess
          handleClose={handleClose}
          resultProductInfo={resultProductInfo!}
        />
      )}

      {currentStep === 'fail' && (
        <PaymentFailed
          title={
            YUNO_ERRORS[errorCode as YunoPaymentErrors]?.title ||
            YUNO_ERRORS.ERROR.title
          }
          description={
            YUNO_ERRORS[errorCode as YunoPaymentErrors]?.description ||
            YUNO_ERRORS.ERROR.description
          }
          totalPrice={totalPrice}
          resultProductInfo={resultProductInfo}
          handleTryAgain={handleTryAgain}
          freeTokensAmount={freeTokensAmount}
        />
      )}

      {showOrderSummary && renderOrderSummary()}
    </Modal>
  );
};
