import * as Sentry from '@sentry/react';
import { BrowserOptions } from '@sentry/react';

import { AppName } from '@/types';

export const APP_NAME: AppName = import.meta.env.VITE_APP_NAME;

export const DIALOGS_MIN_WIDTH = 217;
export const PROFILE_WIDTH = 350;
export const CHAT_MIN_WIDTH = 400;
export const SIDEBAR_WIDTH = 96;

export const ENVIRONMENT = import.meta.env.VITE_NODE_ENV;
export const IS_PRODUCTION = ENVIRONMENT !== 'development';
export const IS_STAGE = import.meta.env.VITE_NODE_ENV === 'stage';
export const BASE_LOCATION_URL = import.meta.env.VITE_BASE_LOCATION_URL;
export const LOCATION_HOSTNAME = import.meta.env.VITE_LOCATION_HOSTNAME;
export const SUPPORT_EMAIL = `support@${LOCATION_HOSTNAME}`;
export const APP_LABEL_CAPITALIZED = import.meta.env.VITE_APP_LABEL_CAPITALIZED;
export const IS_MAIN_APP = APP_NAME === 'main';
export const IS_MIRROR_MAIN_APP = APP_NAME === 'mainMirror';
export const IS_MIRROR_SVITLO_APP = APP_NAME === 'svitlo';

export const IS_LIGHT = APP_NAME === 'svitlo';
export const BASE_URL = import.meta.env.VITE_BASE_URL || 'https://get-honey.ai';
export const BASE_URL_API =
  import.meta.env.VITE_BASE_URL_API || 'https://get-honey.ai/api/';
export const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;
export const APP_TITLE = import.meta.env.VITE_APP_TITLE || 'GetHoney.AI';
export const AMPLITUDE_KEY = import.meta.env.VITE_AMPLITUDE_KEY || '';
export const EMAIL_REGEX =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?/;

export const YUNO_API_KEY = import.meta.env.VITE_YUNO_API_KEY || '';
export const NSFW_ENABLED_KEY = 'nEnabled';
export const ANONYMOUS_USER_ID_KEY = 'anonUserId';
export const REGISTERED_BEFORE_KEY = 'isRegisteredBefore';
export const CONFIRMED_TERMS_KEY = 'hasConfirmedTerms';
export const PRICING_SOURCE_KEY = 'pricingSource';
export const PRICING_INITIAL_SOURCE_KEY = 'pricingInitialSource';
export const PRICING_MODEL_ID_KEY = 'pricingModelId';
export const PRICING_MODEL_TYPE_KEY = 'pricingModelType';

export const PRICING_MODEL_NAME_KEY = 'pricingModelName';

export const IS_NGROK_API = BASE_URL.includes('ngrok-free.app');
export const REFRESH_TOKEN_URL = 'Authenticate/refresh-token';

export const REFRESH_PRE_REGISTER_URL = 'Authenticate/login-pre-registered';
export const LEGAL_COMPANY = IS_MIRROR_SVITLO_APP
  ? 'Svit AI'
  : 'Svitlo Limited';
export const LEGAL_ADDRESS_SHORT = IS_MIRROR_SVITLO_APP
  ? '1065, Nicosia, Cyprus'
  : 'Florinis 7, Greg Tower, 2nd Floor, 1065, Nicosia, Cyprus';

export const LEGAL_ADDRESS = IS_MIRROR_SVITLO_APP
  ? `${LEGAL_COMPANY}, ${LEGAL_ADDRESS_SHORT}`
  : `${LEGAL_COMPANY}, ${LEGAL_ADDRESS_SHORT}`;
export const MAX_DISCOUNT_PERCENT = 70;
export const MAX_DISCOUNT_PERCENT_TOKENS = 25;

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_CONFIG: BrowserOptions = {
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: ENVIRONMENT,
  tracesSampleRate: IS_STAGE ? 0.1 : 1.0,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/get-honey\.online\/api/,
    /^https:\/\/get-honey\.ai\/api/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    'Cannot send data if the connection is not in the "Connected" State.',
    'Invocation canceled due to the underlying connection being closed.',
    "Cannot read properties of undefined (reading 'domInteractive')",
  ],
};
