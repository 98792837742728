import { jwtDecode } from 'jwt-decode';

import { DecodedToken } from '@/types';
import { getTokensFromLocalStorage } from '@/utils/authUtils.ts';

export const isTokenExpired = (): boolean => {
  const { accessToken } = getTokensFromLocalStorage();
  if (!accessToken) return true;

  try {
    const decoded: DecodedToken = jwtDecode(accessToken);
    return decoded.exp * 1000 < Date.now(); // jwt expires in seconds
  } catch (error) {
    return true;
  }
};
