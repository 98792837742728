export const facebookEventMapping = {
  page_viewed: 'PageView',
  user_signed_up: 'CompleteRegistration',
  checkout_page_opened: 'InitiateCheckout',
  purchase: 'Purchase',
} as const;

type FacebookEvent = typeof facebookEventMapping[keyof typeof facebookEventMapping];

export const trackFbEvent = (eventName: FacebookEvent) => {
  if (!window.fbq) return;

  window.fbq('track', eventName);
};