export enum Routes {
  MAIN_PAGE = '/',
  GOOGLE_AUTH_PAGE = '/api/Authenticate/start/web',
  MALE_PAGE = '/male',
  CHAT_PAGE = '/chat',
  HIDDEN_MODAL_AUTH_PAGE = '/hidden-auth',

  PRICING_PAGE = '/pricing',
  SUBSCRIPTION_SUCCESS_PAGE = '/pricing/subscription-success',
  SUBSCRIPTION_FAILED_PAGE = '/pricing/subscription-fail',

  CREATE_CHARACTER_PAGE = '/create-character',
  GALLERY_PAGE = '/gallery',
  GENERATE_IMAGE_PAGE = '/generate-image',
  MY_AI_PAGE = '/my-ai',
  CONTACT_US_PAGE = '/contact-us',
  AFFILIATE_PAGE = '/affiliate',
  SETTINGS_PAGE = '/settings',
  BUY_TOKENS = '/tokens',
  LANDING_PAGE = '/landing',
  LANDING_QUIZ_PAGE = '/landing/quiz',

  LEGAL_PAGE = '/legal',
  PRIVACY_POLICY_PAGE = '/legal/privacy-policy',
  TERMS_OF_SERVICE_PAGE = '/legal/terms-of-service',
  COOKIES_POLICY_PAGE = '/legal/cookies-policy',
  UNDERAGE_POLICY_PAGE = '/legal/underage-policy',
  CONTENT_REMOVAL_POLICY_PAGE = '/legal/content-removal-policy',
  BLOCKED_CONTENT_POLICY_PAGE = '/legal/blocked-content-policy',
  DMCA_POLICY_PAGE = '/legal/dmca-policy',
  COMPLAINT_POLICY_PAGE = '/legal/complaint-policy',
  COMPLAINT_MANAGEMENT_POLICY_PAGE = '/legal/complaint-management-policy',
  EXEMPTION_PAGE = '/legal/exemption',
  ANTI_SLAVERY_POLICY_PAGE = '/legal/anti-slavery-policy',
  CHARGEBACK_FRAUD_MITIGATION_POLICY_PAGE = '/legal/chargeback-fraud-mitigation-policy',
  HIDDEN_PRICING_PAGE = '/magic-page-pricing',
  HIDDEN_TOKENS_PAGE = '/magic-page-tokens',
  QUIZ_PAGE = '/quiz',
}

export enum MediaQueries {
  MOBILE = '(max-width:768px)',
  TABLET = '(max-width:1199px)',
}

export enum Roles {
  ADMIN = 'Admin',
  MODERATOR = 'Moderator',
  USER = 'User',
}
