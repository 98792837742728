import { useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { MediaQueries } from '@/types/enum';

export const useIsFocusedInput = () => {
  const [isFocusedInput, setFocusedInput] = useState(false);

  const isMobile = useMediaQuery(MediaQueries.TABLET);

  const handleFocusChange = useCallback(
    (event: FocusEvent) => {
      if (isMobile) {
        const isFocusin = event.type === 'focusin';
        const nodeName = (event.target as HTMLElement)?.nodeName;
        const isInput = nodeName === 'INPUT' || nodeName === 'TEXTAREA';

        setFocusedInput(isFocusin && isInput);
      }
    },
    [isMobile],
  );

  useEffect(() => {
    window.addEventListener('focusin', handleFocusChange);
    window.addEventListener('focusout', handleFocusChange);

    return () => {
      window.removeEventListener('focusin', handleFocusChange);
      window.removeEventListener('focusout', handleFocusChange);
    };
  }, [handleFocusChange]);

  return isFocusedInput;
};
