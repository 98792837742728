import classNames from 'classnames';
import { useRef } from 'react';

import { Checkbox } from '@/components/Checkbox';
import { Loader } from '@/components/Loader';
import { PaymentFormWrapper } from '@/components/Modals/ModalCheckout/components/PaymentFormWrapper';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import Payment, { Resign } from '@/libs/solidgate-react-sdk';
import { selectUser } from '@/store/public';

import {
  APPLE_PAY_BUTTON_PARAMS,
  GOOGLE_PAY_BUTTON_PARAMS,
  PAYMENT_FORM_PARAMS,
  PAYMENT_FORM_STYLES,
  RESIGN_FORM_APPEARANCE,
  RESIGN_FORM_STYLES,
} from './constants.ts';
import { PaymentFormProps } from './types.ts';

import styles from './styles.module.scss';

import StripeIcon from '@/assets/icons/pricing/stripe.svg?react';

export const PaymentForm = ({
  currentStep,
  resignPaymentData,
  paymentData,
  handleOnError,
  handleOnFail,
  handleSubmit,
  handleReadyResignInstance,
  setIsFormRedirected,
  handleOnMounted,
  shouldSaveCard,
  setShouldSaveCard,
  flow,
  OrderSummary,
}: PaymentFormProps) => {
  const user = useAppSelector(selectUser);
  // const [isHidden, setIsHidden] = useState(true);

  const appleContainerRef = useRef(null);
  const googleContainerRef = useRef(null);

  const isFormHidden = currentStep === 'paymentProcessing';
  const isLoading = currentStep === 'loading';

  return (
    <PaymentFormWrapper OrderSummary={OrderSummary} isHidden={isFormHidden}>
      {isLoading && (
        <Loader
          className={classNames(
            styles.loader,
            !user?.email && styles.withEmail,
            flow === 'resign' && styles.resign,
          )}
        />
      )}

      <div className={styles.container}>
        {flow === 'newCard' && (
          <div className={styles.payButtons}>
            <div ref={appleContainerRef} className={styles.applePay} />
            <div ref={googleContainerRef} className={styles.googlePay} />
          </div>
        )}

        <div
          className={classNames(
            styles.formWrapper,
            !user?.email && styles.withEmail,
            flow === 'resign' && styles.resign,
          )}
        >
          {resignPaymentData && (
            <div className={classNames(isLoading && styles.visuallyHidden)}>
              <Resign
                resignRequest={resignPaymentData}
                appearance={RESIGN_FORM_APPEARANCE}
                styles={RESIGN_FORM_STYLES}
                onError={handleOnError}
                onFail={handleOnFail}
                onReadyResignInstance={handleReadyResignInstance}
                onSubmit={handleSubmit}
                onFormRedirect={() => setIsFormRedirected(true)}
              />
            </div>
          )}

          {paymentData && (
            <div className={classNames(isLoading && styles.visuallyHidden)}>
              <Payment
                merchantData={paymentData}
                styles={PAYMENT_FORM_STYLES}
                formParams={PAYMENT_FORM_PARAMS}
                width="100%"
                googlePayButtonParams={GOOGLE_PAY_BUTTON_PARAMS}
                applePayButtonParams={APPLE_PAY_BUTTON_PARAMS}
                googlePayContainerRef={googleContainerRef}
                applePayContainerRef={appleContainerRef}
                onError={handleOnError}
                onFail={handleOnFail}
                onMounted={handleOnMounted}
                onSubmit={handleSubmit}
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles.poweredBy}>
        <p>Powered by</p>
        <StripeIcon />
      </div>

      {flow === 'newCard' && (
        <Checkbox
          checked={shouldSaveCard}
          onChange={(res) => setShouldSaveCard(res)}
          label="Save this payment method"
          labelClassName={styles.checkboxLabel}
        />
      )}
    </PaymentFormWrapper>
  );
};
