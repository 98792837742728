import classNames from 'classnames';
import { memo, ReactNode } from 'react';

import { getIsTokensProduct } from '@/components/Modals/ModalCheckout/helpers.ts';
import { convertCentsToDollars } from '@/utils/convertCentsToDollars.ts';
import { formatPlural } from '@/utils/formatPlural.ts';

import { PaymentPaymentOrderSummaryProps } from './types.ts';

import styles from './styles.module.scss';

const PaymentOrderSummary = ({
  productTitle,
  totalPrice,
  selectedProduct,
  additionalDiscountPercent,
  className,
}: PaymentPaymentOrderSummaryProps) => {
  const isTokensProduct = getIsTokensProduct(selectedProduct);
  const discountedPrice = selectedProduct.currentPrice;
  const initialPrice = selectedProduct.previousPrice;

  const OrderSummaryContainer = ({ children }: { children: ReactNode }) => (
    <div className={classNames(styles.orderSummary, className)}>
      <p className={styles.orderSummaryTitle}>Order Summary</p>
      <p>
        Product <span>{productTitle}</span>
      </p>
      {children}
    </div>
  );

  if (isTokensProduct) {
    return (
      <OrderSummaryContainer>
        {!!selectedProduct.bonus && (
          <p className={styles.bonus}>
            Bonus <span>{selectedProduct.tokenBonus} Tokens</span>
          </p>
        )}
        <p>
          Price <span>$ {totalPrice}</span>
        </p>
        <div className={styles.divider} />
        <p className={styles.total}>
          TOTAL <span>$ {totalPrice}</span>
        </p>
      </OrderSummaryContainer>
    );
  }

  const hasDiscount = selectedProduct?.discount;
  const finalPrice = selectedProduct?.extraBonusMonth
    ? convertCentsToDollars(selectedProduct.previousPrice, true)
    : totalPrice;

  return (
    <OrderSummaryContainer>
      {!!selectedProduct.extraBonusMonth && (
        <p className={styles.bonus}>
          Bonus
          <span>+{formatPlural(selectedProduct.extraBonusMonth, 'Month')}</span>
        </p>
      )}

      {hasDiscount ? (
        <p>
          Discounted price
          <span>
            $ {convertCentsToDollars(discountedPrice, true)}
            <span className={styles.lineThrough}>
              $ {convertCentsToDollars(initialPrice, true)}
            </span>
          </span>
        </p>
      ) : (
        <p>
          Price <span>$ {finalPrice}</span>
        </p>
      )}

      {!!selectedProduct?.unusedDays && (
        <p>
          Unused balance ({formatPlural(selectedProduct.unusedDays, 'day')})
          <span>
            -$ {convertCentsToDollars(selectedProduct.upgradeDiscount, true)}
          </span>
        </p>
      )}

      {!!additionalDiscountPercent && (
        <p>
          Additional discount <span>-{additionalDiscountPercent}%</span>
        </p>
      )}
      <div className={styles.divider} />
      <p className={styles.total}>
        TOTAL
        <span>
          {!!selectedProduct.extraBonusMonth && (
            <span className={styles.lineThrough}>$ {finalPrice}</span>
          )}
          $ {totalPrice}
        </span>
      </p>
    </OrderSummaryContainer>
  );
};

export default memo(PaymentOrderSummary);
