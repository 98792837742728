import { yupResolver } from '@hookform/resolvers/yup';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Modal } from '@/components/Modals/Modal';
import { GoogleAuth } from '@/components/Modals/ModalAuthContainer/ModalAuthMagicLink/GoogleAuth';
import { PasswordField } from '@/components/PasswordField';
import { Spinner } from '@/components/Spinner';
import {
  ANONYMOUS_USER_ID_KEY,
  CONFIRMED_TERMS_KEY,
  REGISTERED_BEFORE_KEY,
} from '@/const';
import {
  SIGN_IN_BUTTON_CLICKED_EVENT,
  USER_SIGNED_IN_EVENT,
  USER_SIGNED_UP_EVENT,
} from '@/const/dataLayerEvents.ts';
import { FingerprintData } from '@/hooks/useFingerprint.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import { useLoginMutation, useRegisterMutation } from '@/services/authServices';
import {
  LoginData,
  LoginFormValues,
  LoginOldFormValues,
  RegisterData,
  RegisterFormValues,
} from '@/services/authServices/types.ts';
import { selectUser, setAuth } from '@/store/public';
import { Routes } from '@/types/enum.ts';
import { getFingerprintHash } from '@/utils/getFingerprintHash.ts';
import { getIsNSFWUser } from '@/utils/getIsNSFWUser.ts';
import { pushToDataLayer } from '@/utils/gtm.ts';
import { ls } from '@/utils/localStorage.ts';

import { ModalAuthProps } from '../types.ts';
import { ValidationPassword } from './ValidationPassword';
import { schema } from './schema.ts';

import styles from './styles.module.scss';

import MailIcon from '@/assets/icons/auth/mail.svg?react';
import { facebookEventMapping, trackFbEvent } from '@/utils/facebookUtils.ts';

export const ModalAuthWithPassword = ({
  onClose,
  variant,
  setVariant,
  onSuccess,
  setIsMagicLinkForm,
}: ModalAuthProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectUser);

  const [passwordValue, setPasswordValue] = useState('');

  const isLogin = variant === 'login';
  const isNSFW = getIsNSFWUser(user);

  const [
    login,
    {
      data: loginData,
      isLoading: isLoginLoading,
      isError: isLoginError,
      error: loginError,
    },
  ] = useLoginMutation();
  const [
    register,
    {
      data: registerData,
      isLoading: isRegisterLoading,
      isError: isRegisterError,
      error: registerError,
    },
  ] = useRegisterMutation();

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<LoginOldFormValues | RegisterFormValues>({
    resolver: yupResolver(schema),
  });

  const currentPassword = watch('Password');

  useEffect(() => {
    if (variant) setVariant(variant);
  }, [variant]);

  useEffect(() => {
    setPasswordValue(currentPassword ?? '');
  }, [currentPassword]);

  useEffect(() => {
    if (registerData) {
      const { accessToken = '', refreshToken = '' } = registerData;
      dispatch(setAuth({ accessToken, refreshToken }));

      trackFbEvent(facebookEventMapping.user_signed_up);
  
      pushToDataLayer(USER_SIGNED_UP_EVENT, user, {
        auth_method: 'email',
        source: 'sign_up_button',
      });
      ls.set(REGISTERED_BEFORE_KEY, 'true');
      onSuccess?.();
      onClose();
      navigate(Routes.MAIN_PAGE);
    }
  }, [registerData, dispatch]);

  useEffect(() => {
    if (loginData) {
      const { accessToken = '', refreshToken = '' } = loginData;
      dispatch(setAuth({ accessToken, refreshToken }));
      pushToDataLayer(USER_SIGNED_IN_EVENT, user, {
        auth_method: 'email',
      });
      ls.set(REGISTERED_BEFORE_KEY, 'true');
      onSuccess?.();
      onClose();
      navigate(Routes.MAIN_PAGE);
    }
  }, [loginData, dispatch]);

  const onSubmit: SubmitHandler<LoginFormValues | RegisterFormValues> = async (
    data,
  ) => {
    const fingerprintData = (await getFingerprint(true)) as FingerprintData;
    const confirmedTerms = ls.get(CONFIRMED_TERMS_KEY, '');
    const IsNConfirmed = confirmedTerms === 'n';
    const IsSConfirmed = !!confirmedTerms;
    const authDataPayload = {
      ...data,
      Email: data.Email?.trim(),
      Fingerprint: getFingerprintHash(fingerprintData),
      IsNConfirmed,
      IsSConfirmed,
      UserId: ls.get(ANONYMOUS_USER_ID_KEY, ''),
    };

    if (isLogin) {
      pushToDataLayer(SIGN_IN_BUTTON_CLICKED_EVENT, user);
      await login(authDataPayload as LoginData);
    } else {
      await register(authDataPayload as RegisterData);
    }
  };

  return (
    <>
      <Modal onClose={onClose} maxWidth={655}>
        <h2 id="text" className={styles.title}>
          {isLogin ? 'Login' : 'Create Account'}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.input}>
            <MailIcon />
            <input
              id="email_field"
              className={styles.inputStyle}
              placeholder="E-mail"
              {...formRegister('Email')}
            />
          </div>
          {errors.Email && (
            <p id="email_field_error" className={styles.error}>
              {errors.Email.message}
            </p>
          )}

          <PasswordField
            register={formRegister}
            placeholder="Password"
            name="Password"
            inputId="pass_field"
          />

          <p
            id="pass_field_error"
            className={classNames(
              styles.error,
              (!errors.Password || isLoginError || isRegisterError) &&
                styles.hidden,
            )}
          >
            {errors.Password?.message || ''}
          </p>
          {isLogin ? (
            <p
              id="error_from_back"
              className={classNames(
                styles.error,
                !isLoginError && styles.hidden,
              )}
            >
              {(loginError as any)?.data?.errors?.[0]?.errorMessage ||
                'Something went wrong. Please try again.'}
            </p>
          ) : (
            <>
              <p
                id="error_from_back"
                className={classNames(
                  styles.error,
                  !isRegisterError && styles.hidden,
                )}
              >
                {(registerError as any)?.data?.errors?.[0]?.errorMessage ||
                  'Something went wrong. Please try again.'}
              </p>
              <ValidationPassword password={passwordValue} />
            </>
          )}
          {/*{isLogin && <p className={styles.forgotPassword}>Forgot password?</p>}*/}
          <Button
            id="enter_btn"
            className={styles.signIn}
            type="submit"
            disabled={
              isLogin
                ? isLoginLoading
                : isRegisterLoading || passwordValue.length < 8
            }
          >
            {isLogin ? (
              isLoginLoading ? (
                <Spinner />
              ) : (
                'Login'
              )
            ) : isRegisterLoading ? (
              <Spinner />
            ) : (
              'Create'
            )}
          </Button>
        </form>
        <p className={styles.or}>or</p>
        <GoogleAuth />
        {isLogin ? (
          <p className={styles.notHaveAccount}>
            Don't have an account yet?{' '}
            <button
              id="switch_btn"
              onClick={() => {
                setVariant('register');
                setIsMagicLinkForm?.(true);
              }}
            >
              Register
            </button>
          </p>
        ) : (
          <div className={styles.terms}>
            By signing up, you agree to{' '}
            <Link
              to={Routes.TERMS_OF_SERVICE_PAGE}
              onClick={onClose}
              id="terms_btn"
            >
              Terms of service
            </Link>
          </div>
        )}
        {!isLogin && (
          <p className={styles.notHaveAccount}>
            Already have an account yet?{' '}
            <button id="switch_btn" onClick={() => setVariant('login')}>
              Login
            </button>
          </p>
        )}

        {isLogin && !isNSFW && (
          <p className={styles.magicLinkText}>
            You choose to log in manually. Enter your details or{' '}
            <button
              id="manual_log_btn"
              onClick={() => setIsMagicLinkForm?.(true)}
            >
              log in using the magic link
            </button>
            .
          </p>
        )}
      </Modal>
    </>
  );
};
