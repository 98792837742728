import useMediaQuery from '@mui/material/useMediaQuery';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';

import ModalPrePaywall from '@/components/Modals/ModalPrePaywall';
import ScrollToTop from '@/components/ScrollToTop';
import {
  CONFIRMED_TERMS_KEY,
  IS_MIRROR_SVITLO_APP,
  NSFW_ENABLED_KEY,
} from '@/const';
import { FingerprintData, useFingerprint } from '@/hooks/useFingerprint.tsx';
import { useIsFocusedInput } from '@/hooks/useIsFocusedInput';
import { usePaywallModals } from '@/hooks/usePaywallModals.tsx';
import { usePreserveQueryParams } from '@/hooks/usePreserveQueryParams.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import { BottomNavigation } from '@/layout/BottomNavigation';
import { Sidebar } from '@/layout/Sidebar';
import PricingPage from '@/pages/pricing';
import { usePreRegisterMutation } from '@/services/authServices';
import { useGetTokensQuery } from '@/services/paymentsServices';
import { useGetUserInfoQuery } from '@/services/userServices';
import { selectAccessToken, setAuth } from '@/store/public';
import { MediaQueries } from '@/types/enum.ts';
import { getTokensFromLocalStorage } from '@/utils/authUtils.ts';
import { getFingerprintHash } from '@/utils/getFingerprintHash.ts';
import { ls } from '@/utils/localStorage.ts';

import { Header } from './Header';

import styles from './styles.module.scss';

export const Layout = () => {
  useFingerprint(true);
  usePreserveQueryParams();
  const [searchParams] = useSearchParams();
  const { accessToken, refreshToken } = getTokensFromLocalStorage();
  const isMobile = useMediaQuery(MediaQueries.TABLET);
  const dispatch = useAppDispatch();

  const savedToken = useAppSelector(selectAccessToken);

  const [isSidebarAnimationDisabled, setIsSidebarAnimationDisabled] =
    useState(true);

  const isFocusedInput = useIsFocusedInput();

  const [preRegister] = usePreRegisterMutation();
  const { data: user } = useGetUserInfoQuery(null, {
    skip: !accessToken || !savedToken,
  });
  useGetTokensQuery(null, {
    skip: !user?.activeSubscription,
  });

  const { isModalPrePaywallOpened, isModalPaywallOpened } = usePaywallModals();

  useEffect(() => {
    // if (IS_MIRROR_MAIN_APP) {
    //   window.location.href = 'https://get-honey.ai/?utm_source=conf';
    // }

    const postPreRegister = async () => {
      if (accessToken) return;

      const fingerprintData = (await getFingerprint(true)) as FingerprintData;
      const confirmedTerms = ls.get(CONFIRMED_TERMS_KEY, '');
      const utmDataSaved = ls.get(NSFW_ENABLED_KEY, {});
      const utmSourceFromUrl = searchParams.get('utm_source');
      const utmSourceFromLS = utmDataSaved?.utmSource;
      const { data } = await preRegister({
        fingerprint: getFingerprintHash(fingerprintData),
        isNConfirmed: confirmedTerms === 'n',
        isSConfirmed: !!confirmedTerms,
        utmSource: utmSourceFromLS || utmSourceFromUrl || '',
      });
      if (data) {
        dispatch(
          setAuth({
            generatedUserId: data.generatedUserId,
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
            isLoginOrRegister: false,
          }),
        );
      }
    };

    const setFingerprintToLocalStorage = async () => {
      const fingerprintData = (await getFingerprint(true)) as FingerprintData;
      const fingerprint = getFingerprintHash(fingerprintData);
      ls.set('uniqueBrowserData', fingerprint);
    };

    if (accessToken && refreshToken) {
      dispatch(
        setAuth({ accessToken, refreshToken, isLoginOrRegister: false }),
      );
    }

    postPreRegister();
    setFingerprintToLocalStorage();

    setTimeout(() => {
      setIsSidebarAnimationDisabled(false);
    }, 150);
  }, []);

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className={styles.content}>
        {(!IS_MIRROR_SVITLO_APP || isMobile) && <Sidebar />}
        <main
          className={classNames(
            styles.main,
            isSidebarAnimationDisabled && styles.animationDisabled,
            IS_MIRROR_SVITLO_APP && styles.noPaddings,
          )}
        >
          <Outlet />
        </main>
        {isMobile && !isFocusedInput && <BottomNavigation />}
      </div>

      {isModalPrePaywallOpened && <ModalPrePaywall />}
      {isModalPaywallOpened && <PricingPage isModal />}
    </>
  );
};
