import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@/components/Button';
import { APP_LABEL_CAPITALIZED } from '@/const';
import { CONTACT_US_BUTTON_CLICKED_ON_CHECKOUT_PAGE_EVENT } from '@/const/dataLayerEvents.ts';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectUser } from '@/store/public';
import { Routes } from '@/types/enum.ts';
import { convertCentsToDollars } from '@/utils/convertCentsToDollars.ts';
import { pushToDataLayer } from '@/utils/gtm.ts';

import { PaymentSuccessProps } from './types.ts';

import styles from './styles.module.scss';

import SuccessIcon from '@/assets/icons/success-green-check.svg?react';

export const PaymentSuccess = ({
  handleClose,
  resultProductInfo,
}: PaymentSuccessProps) => {
  const user = useAppSelector(selectUser);

  const [timeLeft, setTimeLeft] = useState(10);

  useEffect(() => {
    if (timeLeft <= 0) {
      handleClose();
      return;
    }

    const timer = setTimeout(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <div className={styles.successContainer}>
      <div className={styles.successHeader}>
        <SuccessIcon />
        <p>Payment Completed</p>
      </div>
      <div className={styles.successInfo}>
        <p>
          <span>Payment to:</span>
          {APP_LABEL_CAPITALIZED}
        </p>
        <p>
          <span>Payment date:</span>
          {dayjs(new Date()).format('DD.MM.YY')}
        </p>
      </div>

      <div className={styles.successInfo}>
        <p>
          <span>Product:</span>
          {resultProductInfo?.isSubscriptionProduct
            ? 'Premium Access'
            : resultProductInfo?.name}
        </p>
        <p>
          <span>Total paid:</span>${' '}
          {convertCentsToDollars(resultProductInfo?.amountPaid, true)}
        </p>
      </div>

      <p
        className={classNames(
          styles.productInfo,
          !resultProductInfo?.isSubscriptionProduct && styles.tokens,
        )}
      >
        {resultProductInfo?.name}
      </p>

      <p className={styles.helperText}>
        This payment will be shown as {APP_LABEL_CAPITALIZED} in your bank
        statement.
      </p>
      <Button onClick={handleClose} fullWidth className={styles.proceedButton}>
        Proceed (0:{timeLeft < 10 ? `0${timeLeft}` : timeLeft})
      </Button>

      <p className={styles.successHelpText}>
        Have a question?{' '}
        <Link
          onClick={() => {
            pushToDataLayer(
              CONTACT_US_BUTTON_CLICKED_ON_CHECKOUT_PAGE_EVENT,
              user,
            );
            handleClose();
          }}
          to={Routes.CONTACT_US_PAGE}
        >
          Contact Us
        </Link>
      </p>
    </div>
  );
};
