import { NavigateFunction } from 'react-router-dom';

import {
  PRICING_INITIAL_SOURCE_KEY,
  PRICING_MODEL_ID_KEY,
  PRICING_MODEL_NAME_KEY,
  PRICING_MODEL_TYPE_KEY,
  PRICING_SOURCE_KEY,
} from '@/const';
import { Routes } from '@/types/enum.ts';
import { ls } from '@/utils/localStorage.ts';

const CHAT_SOURCES = [
  'chat',
  'welcome_video',
  'welcome_photo',
  'upsell_tokens',
];

const MY_AI_SOURCES = ['create_character', 'my_ai'];

export const navigateAfterPurchase = (navigate: NavigateFunction): void => {
  const initialSource = ls.get(PRICING_INITIAL_SOURCE_KEY, '');
  const source = ls.get(PRICING_SOURCE_KEY, '');
  const modelId = ls.get(PRICING_MODEL_ID_KEY, '');

  const modifiedSource = source === 'upsell_tokens' ? initialSource : source;

  if (modelId && CHAT_SOURCES.includes(modifiedSource)) {
    navigate(`${Routes.CHAT_PAGE}/${modelId}`);
  } else if (MY_AI_SOURCES.includes(modifiedSource)) {
    navigate(Routes.MY_AI_PAGE);
  } else {
    navigate(Routes.MAIN_PAGE);
  }

  ls.remove(PRICING_SOURCE_KEY);
  ls.remove(PRICING_MODEL_ID_KEY);
  ls.remove(PRICING_INITIAL_SOURCE_KEY);
  ls.remove(PRICING_MODEL_NAME_KEY);
  ls.remove(PRICING_MODEL_TYPE_KEY);
};
