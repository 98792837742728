import classNames from 'classnames';
import { memo } from 'react';

import { Button } from '@/components/Button';
import { Loader } from '@/components/Loader';
import { IS_MIRROR_SVITLO_APP } from '@/const';
import { useContentType } from '@/hooks/useContentType.tsx';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { PricingBlockBenefits } from '@/pages/pricing/PricingBlock/Benefits';
import { PricingBlockCancelSubscription } from '@/pages/pricing/PricingBlock/CancelSubscription';
import { PricingBlockDiscount } from '@/pages/pricing/PricingBlock/Discount';
import PricingBlockProduct from '@/pages/pricing/PricingBlock/Product';
import { PRICING_PAYMENT_ICONS } from '@/pages/pricing/PricingBlock/constants.ts';
import { getDisclaimerText } from '@/pages/pricing/PricingBlock/helpers.tsx';
import { convertSubscriptionPeriodToMonthsNumber } from '@/pages/pricing/helpers.ts';
import { selectIsYunoPaymentMethod } from '@/store/public';

import { PaymentButtonTextEnum, PricingBlockProps } from '../types.ts';

import styles from './styles.module.scss';

import CrownIcon from '@/assets/icons/crown.svg?react';
import FireDiscountIcon from '@/assets/icons/pricing/fire-discount.svg?react';
import ProtectionIcon from '@/assets/icons/protection-green.svg?react';
import TokenIcon from '@/assets/icons/token.svg?react';

const MONTHS_IN_YEAR = 12;

const PricingBlock = ({
  products,
  isLoading,
  paymentButtonText,
  selectedProduct,
  handleActionClick,
  setSelectedProduct,
  startCancellationFlow,
  isLifeTimeSubscription,
  activeSubscription,
  isModal,
}: PricingBlockProps) => {
  const { user, isNSFWContent } = useContentType();
  const isYunoPaymentMethod = useAppSelector(selectIsYunoPaymentMethod);

  const handleUpgradeBonusClick = () => {
    setSelectedProduct(
      products?.find((product) => product.periodType === 'year') || null,
    );
    handleActionClick();
  };

  const disclaimer = getDisclaimerText(
    selectedProduct,
    !!user?.activeSubscription,
  );
  const activeSubscriptionInMonths = convertSubscriptionPeriodToMonthsNumber(
    activeSubscription?.periodType,
    activeSubscription?.periodValue,
  );
  const selectedProductInMonths = convertSubscriptionPeriodToMonthsNumber(
    selectedProduct?.periodType,
    selectedProduct?.periodValue,
  );
  const showDisclaimer = selectedProductInMonths > activeSubscriptionInMonths;
  const showUpgradeBanner =
    !IS_MIRROR_SVITLO_APP &&
    !isYunoPaymentMethod &&
    activeSubscription &&
    user?.activeSubscription &&
    activeSubscriptionInMonths < MONTHS_IN_YEAR;
  const isPaymentButtonDisabled =
    IS_MIRROR_SVITLO_APP ||
    !selectedProduct ||
    (user?.activeSubscription &&
      paymentButtonText === PaymentButtonTextEnum.Continue) ||
    (user?.activeSubscription && isYunoPaymentMethod);

  return (
    <>
      <PricingBlockDiscount
        isLifeTimeSubscription={isLifeTimeSubscription}
        isLoading={isLoading}
        activeSubscription={activeSubscription}
        isModal={isModal}
      />

      <div className={styles.pricingCenterContainer}>
        {IS_MIRROR_SVITLO_APP ? (
          <p className={styles.comingSoonText}>Coming Soon</p>
        ) : (
          <div id="token_badge" className={styles.tokensBadge}>
            <TokenIcon /> Get 100 Tokens every month
          </div>
        )}

        {showUpgradeBanner && (
          <div className={styles.upgradeTopBanner}>
            <FireDiscountIcon /> Upgrade anytime, and we'll credit your
            remaining days
          </div>
        )}

        <div
          className={classNames(
            styles.pricingProductsContainer,
            IS_MIRROR_SVITLO_APP && styles.disabled,
          )}
        >
          {isLoading ? (
            <Loader className={styles.loader} />
          ) : (
            products?.map((product, index) => (
              <PricingBlockProduct
                key={product.id}
                index={index}
                isLifeTimeSubscription={isLifeTimeSubscription}
                product={product}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                activeSubscriptionInMonths={activeSubscriptionInMonths}
              />
            ))
          )}
        </div>

        <div
          className={classNames(
            styles.noAdultContainer,
            !isNSFWContent && styles.centered,
          )}
        >
          <ProtectionIcon />
          <p id="no_adult_text" className={styles.noAdultTextDesktop}>
            {isNSFWContent
              ? 'No adult transaction in your bank statement. Rest assured, your payment is 100% anonymous.'
              : 'Your payment is 100% anonymous!'}
          </p>
          <p id="no_adult_text_mobile" className={styles.noAdultTextMobile}>
            Your payment is 100% anonymous!
          </p>
        </div>

        {!isLifeTimeSubscription && (
          <Button
            id="pay_btn"
            disabled={isPaymentButtonDisabled}
            onClick={handleActionClick}
            className={styles.payButton}
          >
            {paymentButtonText}
          </Button>
        )}

        {showUpgradeBanner && (
          <div className={styles.upgradeBonusBanner}>
            <p className={styles.upgradeBonusTitle}>
              Switch to Yearly and Unlock 6 Bonus Months!
            </p>
            <div className={styles.upgradeBonusDescriptionContainer}>
              <p className={styles.upgradeBonusDescription}>
                Switch to an annual plan today and enjoy&nbsp;6&nbsp;extra
                months of premium access at no extra cost
              </p>
              <Button
                onClick={handleUpgradeBonusClick}
                className={styles.upgradeBonusActionButton}
              >
                <CrownIcon />
                Get My Bonus
              </Button>
            </div>
          </div>
        )}

        {showDisclaimer && <p className={styles.disclaimer}>{disclaimer}</p>}

        {!isLifeTimeSubscription && !isNSFWContent && (
          <p className={styles.paymentBilledText}>
            Payment will be shown as{' '}
            {IS_MIRROR_SVITLO_APP ? 'Svitlo AI' : 'GetHoney AI'} <br />
          </p>
        )}

        {user?.activeSubscription && !isLifeTimeSubscription && (
          <PricingBlockCancelSubscription
            startCancellationFlow={startCancellationFlow}
          />
        )}

        <div className={styles.paymentMethods}>
          {PRICING_PAYMENT_ICONS.map((Icon, index) => (
            <Icon key={index} />
          ))}
        </div>
      </div>

      <PricingBlockBenefits />
    </>
  );
};

export default memo(PricingBlock);
