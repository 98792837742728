import '@styles/index.scss';
import { useEffect } from 'react';

import { APP_TITLE } from '@/const';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectIsYunoPaymentMethod } from '@/store/public';

import { AppRouter } from './routes';
import { facebookEventMapping, trackFbEvent } from './utils/facebookUtils';

function App() {
  const isYuno = useAppSelector(selectIsYunoPaymentMethod);

  useEffect(() => {
    const handleViteError = () => {
      window.location.reload();
    };

    trackFbEvent(facebookEventMapping.page_viewed);

    window.addEventListener('vite:preloadError', handleViteError);

    document.title = APP_TITLE;

    return () => {
      window.removeEventListener('vite:preloadError', handleViteError);
    };
  }, []);

  useEffect(() => {
    if (!isYuno) return;

    const script = document.createElement('script');
    script.src = 'https://sdk-web.y.uno/v1/static/js/main.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      if (script) document.body.removeChild(script);
    };
  }, [isYuno]);

  return <AppRouter />;
}

export default App;
